<template>
  <v-app>
    <v-container>
      <v-toolbar flat style="height: 100px">
        <v-tabs v-model="xSelectedTab" class="px-0 pt-10px">
          <v-tab
            v-for="(myClass, myClassesIndex) in myClasses"
            :key="'myClass' + myClassesIndex"
          >
            {{ myClass.STUDENT_GROUP_NAME }}
          </v-tab>
        </v-tabs>
        <v-select
          class="mt-10 mr-2"
          style="background-color: #f8bbd0"
          :items="[1, 2, 3, 4]"
          v-model="currentSelectedSemester"
          label="Улирал сонгоx"
        >
        </v-select>
        <v-select
          class="mt-10 mr-2"
          style="background-color: #ffe082"
          :items="_getWeeks()"
          v-model="currentSelectedWeek"
          label="Долоо хоног сонгоx"
        >
        </v-select>
      </v-toolbar>
      <v-tabs-items v-model="xSelectedTab" class="px-0">
        <v-tab-item
          v-for="(myClass, myClassesIndex) in myClasses"
          :key="'myClass' + myClassesIndex"
        >
          <ClassIrzWeekly
            :dailyAttendances="dailyAttendances"
            :attendances="attendances"
            :calendarData="calendarData"
            :currentWeekDays="currentWeekDays"
            :currentSelectedWeek="currentSelectedWeek"
            :zClassGroup="myClass"
            :myClass="myClass"
            v-bind="$attrs"
            :statAbsent="statAbsent"
          ></ClassIrzWeekly>
        </v-tab-item>
      </v-tabs-items>
    </v-container>
  </v-app>
</template>
<script>
import { mapState } from "vuex";
import { sync } from "vuex-pathify";
import ClassIrzWeekly from "@/views/Pages/Irz/ClassIrzWeekly.vue";
const fb = require("@/firebaseConfig.js");
export default {
  components: {
    ClassIrzWeekly,
  },
  data: () => ({
    currentSelectedSemester: 1,
    currentSelectedWeek: null,
    statAbsent: -1,
    xSelectedTab: 0,
    myClasses: null,
    dailyAttendances: null,
    attendances: null,
    calendarData: null,
    currentWeekDays: null,
  }),
  props: {
    teacherId: {
      type: Number,
    },
  },
  watch: {
    currentSelectedSemester() {
      // console.log(val, this.currentSelectedSemester);
      this.myClasses[this.xSelectedTab].ref
        .collection(
          "calendar-" +
            this.userData.school.currentYear +
            "-" +
            this.currentSelectedSemester
        )
        .orderBy("courseInfo.COURSE_NAME", "asc")
        .onSnapshot((docs) => {
          this.calendarData = [];
          docs.forEach(async (doc) => {
            let cal = doc.data();
            cal.ref = doc.ref;
            cal.id = doc.id;
            if (cal.lessonGroup) {
              cal.lessonGroup.ref.get().then((g) => {
                if (g.exists == false) cal.ref.delete();
                else {
                  let calG = g.data();
                  calG.id = g.id;
                  calG.ref = g.ref;
                  cal.lessonGroup = calG;
                  var xx = this.calendarData.find((item) => item.id == cal.id);
                  if (!xx) this.calendarData.push(cal);
                }
              });
            } else {
              cal.lessonGroup = null;
              var zzz = await cal.lessonRef.get();
              if (zzz.exists) {
                cal.byTeachers = zzz.data().byTeachers
                  ? zzz.data().byTeachers
                  : null;
              } else cal.byTeachers = [];
              var xx = this.calendarData.find((item) => item.id == cal.id);
              if (!xx) this.calendarData.push(cal);
            }
          });
        });
    },
    currentSelectedWeek() {
      this.currentWeekDays = [];
      this.attendances = [];
      this.dailyAttendances = [];
      var ss = this.$store.state.calendarButez2.find(
        (item) => item.session == this.currentSelectedSemester
      );
      if (ss) {
        ss.months.forEach((mm) => {
          mm.days.forEach((dd) => {
            if (dd.weekNumber == this.currentSelectedWeek) {
              dd.month = mm.name;
              fb.db
                .collectionGroup("executions")
                .where(
                  "classGroupRef",
                  "==",
                  this.myClasses[this.xSelectedTab].ref
                )
                .where("year", "==", new Date().getFullYear())
                .where("month", "==", dd.month)
                .where("day", "==", dd.day)
                .get()
                .then((docs) => {
                  var executions = [];
                  docs.forEach((doc) => {
                    let execution = doc.data();
                    execution.ref = doc.ref;
                    execution.id = doc.id;
                    executions.push(execution);
                  });
                  this.dailyAttendances.push({ executions: executions });
                });
              this._getSelectedClassGroup()
                .ref.collection(
                  "attendances-" + this.userData.school.currentYear
                )
                .where("year", "==", new Date().getFullYear())
                .where("month", "==", dd.month)
                .where("day", "==", dd.day)
                .where("daily_attendance", "==", false)
                .get()
                .then((docs) => {
                  docs.forEach((doc) => {
                    let att = doc.data();
                    att.id = doc.id;
                    att.ref = doc.ref;
                    this.attendances.push(att);
                  });
                });
              this.currentWeekDays.push(dd);
            }
          });
        });
      }
    },
  },
  computed: {
    ...mapState(["userData", "currentUser"]),
    ...sync("*"),
  },
  created() {
    var xx =
      this.teacherId == undefined ? this.userData.PERSON_ID : this.teacherId;
    fb.db
      .collectionGroup("programs")
      .where("TEACHER_ID", "==", xx)
      .get()
      .then((docs) => {
        this.myClasses = [];
        docs.forEach((doc) => {
          let cg = doc.data();
          cg.id = doc.id;
          cg.ref = doc.ref;
          if (!cg.deleted) {
            if (!cg.ref.path.includes("/departments/")) {
              var found = this.myClasses.find((cc) => cc.id == cg.id);
              if (!found) this.myClasses.push(cg);
            }
          }
        });
        this.currentSelectedSemester = 4;
        this.currentSelectedWeek = this._getCurrentSchoolWeekNumber();
      });
  },
  methods: {
    _getSelectedClassGroup() {
      if (this.myClasses) return this.myClasses[this.xSelectedTab];
    },
    _getWeeks() {
      var xx = this.$store.state.calendarButez2.find(
        (item) => item.session == this.currentSelectedSemester
      );
      if (xx) return xx.weeks;
      else return null;
    },
    _getCurrentSchoolWeekNumber() {
      var date = new Date();
      var month = date.getMonth() + 1;
      var day = date.getDate();
      var cc = null;
      this.$store.state.calendarButez2.forEach((sem) => {
        sem.months.forEach((mm) => {
          if (mm.name == month) {
            mm.days.forEach((dd) => {
              if (dd.day == day) {
                cc = dd.weekNumber;
                return;
              }
            });
          }
        });
      });
      return cc;
    },
  },
};
</script>
